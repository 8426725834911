import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const WhoWeAreContainer = styled(Box)({
  padding: "20px 20px 40px",
  backgroundColor: "#A5D6A7", // Light green color
  color: "#333",
  textAlign: "center",
});

const WhoWeAre: React.FC = () => {
  return (
    <WhoWeAreContainer>
      <Typography variant="h5" component="h2" gutterBottom>
        Who We Are
      </Typography>
      <Typography variant="body1" paragraph fontSize={30}>
        Creating a healthy mind, body, and soul in your overall well-being
      </Typography>

      <Typography variant="body1" paragraph>
        Ayush Polyclinic & Ayurveda Hospital offers a variety of panchakarma therapies and a well-equipped operating
        room for performing Ayurvedic surgical operations. Along with well-trained nursing staff, duty doctors are
        available round-the-clock to take care of you.
      </Typography>

      <Typography variant="body1" paragraph fontSize={30}>
        Preventive and curative treatment
        <br />
        स्वस्थस्य स्वास्थ्य रक्षणं । आतुरस्य विकार प्रशमनं च ।।
      </Typography>
      <Typography variant="body1">
        Facilitating the healthy person in maintaining and protecting his health and helping in a diseased person's
        recovery & long-term wellness.
      </Typography>
    </WhoWeAreContainer>
  );
};

export default WhoWeAre;
