import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import TopHeader from "./components/TopHeader";

const App = () => {
  return (
    <>
      <TopHeader />
      <div className="mainContainer">
        <AppRoutes />
      </div>
    </>
  );
};

export default App;
