import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const AboutContainer = styled(Box)({
  padding: "20px 20px",
  backgroundColor: "#F0F4C3", // Light yellowish-green
  color: "#333",
});

const AboutHospital: React.FC = () => {
  return (
    <AboutContainer>
      <Typography variant="h6" gutterBottom>
        Wellness And Health through Ayurveda
      </Typography>
      <Typography variant="body1">
        Established in the year 2001, the hospital offers preventive and curative Ayurveda treatment. Initially, started
        with 05 inpatient beds, but now it has a full-fledged facility for 15 inpatient beds. The hospital has a
        well-equipped operation theatre to perform Ayurveda surgical procedures and an intensive care unit for handling
        emergency clinical conditions. Duty doctors are available round the clock with well-trained nursing staff. For
        Panchakarma, separate male and female therapy units with skilled therapists are available. Yoga and
        physiotherapy treatment are also available.
      </Typography>
    </AboutContainer>
  );
};

export default AboutHospital;
