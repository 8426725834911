import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SlideImage1 from "../assets/images/1_slider.png";
import SlideImage2 from "../assets/images/2_slider.png";
import SlideImage3 from "../assets/images/3_slider.png";
import SlideImage4 from "../assets/images/4_slider.png";
import SlideImage5 from "../assets/images/5_slider.png";
import SlideImage6 from "../assets/images/6_slider.png";
import SlideImage7 from "../assets/images/7_slider.png";

const SliderContainer = styled(Box)({
  margin: "20px auto",
});

const images = [
  {
    url: SlideImage1,
    description: "Experience the best Ayurveda treatments.",
  },
  {
    url: SlideImage2,
    description: "Experience the best Ayurveda treatments.",
  },
  {
    url: SlideImage3,
    description: "Experience the best Ayurveda treatments.",
  },
  {
    url: SlideImage4,
    description: "Experience the best Ayurveda treatments.",
  },
  {
    url: SlideImage5,
    description: "Experience the best Ayurveda treatments.",
  },
  {
    url: SlideImage6,
    description: "Experience the best Ayurveda treatments.",
  },
  {
    url: SlideImage7,
    description: "Experience the best Ayurveda treatments.",
  },
];

const ImageSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box key={index} textAlign="center">
            <img src={image.url} alt={`slide-${index}`} style={{ width: "100%", borderRadius: "10px" }} />
            {/* <Typography variant="body1" marginTop="10px">
              {image.description}
            </Typography> */}
          </Box>
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default ImageSlider;
