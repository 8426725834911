import React from "react";
import { Grid, Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MaganJi from "../assets/images/magan2.png";
import Annee from "../assets/images/anee.jpeg";
import Vikas from "../assets/images/vikas.jpeg";
import Sanket from "../assets/images/sanket.png";

const teamMembers = [
  {
    name: "Dr. Maganlal Patidar",
    qualification: "B.A.M.S., CCH, CGO MUMBAI",
    q2: "Diploma in kshar Sutra & Anorectal (Parul University)",
    experience: "30 years of experience",
    skills: "Family Physician & Ayurvedic Consultant",
    position: "Managing Director and Ayurveda Physician",
    availability: "Everyday, 9 AM - 7 PM",
    image: MaganJi,
  },
  {
    name: "Dr. Sanket Patidar",
    qualification: "B.A.M.S, C.R.A.V (Kaychikitsa)",
    experience: "5 years of experience",
    skills: "Anorectal & Ayurveda Physician",
    position: "Ayurveda Physician",
    availability: "Everyday, 9 AM - 7 PM",
    image: Sanket,
  },
  {
    name: "Dr. Vikas Patidar",
    qualification: "B.A.M.S, C.R.A.V (Kaychikitsa)",
    experience: "5 years of experience",
    skills: "Anorectal & Ayurveda Physician",
    position: "Ayurveda Physician",
    availability: "Everyday, 9 AM - 7 PM",
    image: Vikas,
  },
  {
    name: "Dr. Anee Patidar",
    qualification: "B.A.M.S (Ayurvedacharya)",
    experience: "4-5 years of experience",
    skills: "Anorectal & Ayurveda Physician",
    position: "Ayurveda Physician",
    availability: "Everyday, 9 AM - 7 PM",
    image: Annee,
  },
];

const CardWrapper = styled(Card)({
  margin: "10px",
  textAlign: "left", // Center the text content
});

const ImageWrapper = styled("div")({
  width: "228px", // Control the size of the circle
  height: "266px",
  borderRadius: "13%", // Make the image circular
  overflow: "hidden", // Hide any overflow to keep the circular shape
  margin: "10px auto", // Center the image horizontally
});

const MeetOurTeam: React.FC = () => {
  return (
    <div className="docContainer">
      <Box>
        <Typography variant="h5" component="h2" textAlign="center" gutterBottom className="meetLabel">
          Meet Our Team
        </Typography>
        <Grid container justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <CardWrapper>
                <ImageWrapper>
                  <CardMedia
                    component="img"
                    alt={member.name}
                    image={member.image}
                    sx={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensures the image covers the entire div
                  />
                </ImageWrapper>
                <CardContent>
                  <Typography variant="h6">{member.name}</Typography>
                  <Typography variant="body2">{member.qualification}</Typography>
                  {member.q2 && <Typography variant="body2">{member.q2}</Typography>}
                  <Typography variant="body2">{member.experience}</Typography>
                  <Typography variant="body2">{member.skills}</Typography>
                  <Typography variant="body2">{member.position}</Typography>
                  <Typography variant="body2">{member.availability}</Typography>
                </CardContent>
              </CardWrapper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default MeetOurTeam;
