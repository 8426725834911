import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const DevBanner = styled(Box)({
  backgroundColor: "#1a2d47", // Green color to match the theme
  color: "#fff",
  padding: "10px",
  textAlign: "center",
});

const DeveloperBanner: React.FC = () => {
  return (
    <DevBanner>
      <Typography variant="body1">
        App Developed by: Harish Patidar - CEO/Co-Founder - PatidarTech Solutions India
      </Typography>
    </DevBanner>
  );
};

export default DeveloperBanner;
