import React from "react";
import { Grid, Card, CardContent, CardMedia, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Male from "../assets/images/male.jpg";
import Dhruvil from "../assets/images/dhruvil.png";
import ShivShankar from "../assets/images/shivShankar.png";
import Poorav from "../assets/images/poorav.png";
import Keval from "../assets/images/keval.png";
import Nisarga from "../assets/images/nisarga.png";

const visitors = [
  {
    name: "Dr. Dhruvil Patel",
    education: "MBBS, MD, DGO",
    experience: "15+ years of experience",
    department: "GYNECOLOGIST & LAPROSCOPIC SURGEON",
    location: "GARV GYNEC HOSPITAL HIMMATNAGAR",
    timing: "9AM - 12PM",
    date: "1st & 3rd Wednesday of the Month",
    image: Dhruvil,
  },
  {
    name: "Dr. Shiv Shanker Kaushik",
    education: "MBBS, MS(ENT)",
    experience: "11+ years of experience",
    department: "ENT SPECIALIST",
    location: "KAUSHIK ENT HOSPITAL UDAIPUR",
    timing: "9AM - 1PM",
    date: "Last Saturday of the Month",
    image: ShivShankar,
  },
  {
    name: "Dr. Keval Patel",
    education: "MBBS, MS",
    experience: "10+ years of experience",
    department: "Orthopedic surgeon",
    location: "PLUTO SUPER SPECIALITY SURGICAL HOSPITAL & TRAUMA CENTER HIMMATNAGAR",
    timing: "9AM - 1PM",
    date: "1st Sunday of every Month",
    image: Keval,
  },
  {
    name: "Dr. Poorav Patel",
    education: "MBBS, MD (Gold medalist)",
    experience: "10+ years of experience",
    department: "PSYCHIATRIST",
    location: "KESHAV HOSPITAL HIMMATNAGAR",
    timing: "9AM - 1PM",
    date: "1st Sunday of every Month",
    image: Poorav,
  },
  {
    name: "Dr. Nisarga Modi",
    education: "MBBS, MB, DDV",
    experience: "10+ years of experience",
    department: "DERMETOLOGIST",
    location: "Dr.Modi's Skin & Smie Clinic HIMMATNAGAR",
    timing: "9AM - 1PM",
    date: "1st Sunday of every month",
    image: Nisarga,
  },
  {
    name: "Dr. Naresh Joshi",
    education: "MBBS, MS(Surgeon)",
    experience: "30+ years of experience",
    department: "Genral Surgeon",
    location: "MANSI SURGICAL HOSPITAL MODASA(GUJRAT)",
    timing: "9AM - 1PM",
    date: "2nd Sunday of every Month",
    image: Male,
  },
];

const CardWrapper = styled(Card)({
  margin: "10px",
});

const ImageWrapper = styled("div")({
  width: "228px", // Control the size of the circle
  height: "266px",
  borderRadius: "13%", // Slightly rounded image
  overflow: "hidden", // Hide any overflow to maintain the shape
  margin: "10px auto", // Center the image horizontally
});

const HighlightText = styled(Typography)({
  color: "#ff5722", // Use a color that stands out
  fontWeight: "bold",
  fontSize: "16px",
});

const DoctorsVisitors: React.FC = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("bottomBanner");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="docContainer">
      <Box>
        <Typography variant="h5" component="h2" textAlign="center" gutterBottom className="docVisitLabel">
          Doctor’s Visitors for this Week
        </Typography>
        <Grid container justifyContent="center">
          {visitors.map((visitor, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <CardWrapper>
                <ImageWrapper>
                  <CardMedia
                    component="img"
                    alt={visitor.name}
                    image={visitor.image}
                    sx={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensures the image covers the entire div
                  />
                </ImageWrapper>
                <CardContent>
                  <Typography variant="h6">{visitor.name}</Typography>
                  <Typography variant="body2">{visitor.education}</Typography>
                  <Typography variant="body2">{visitor.experience}</Typography>
                  <Typography variant="body2">{visitor.department}</Typography>
                  <Typography variant="body2">{visitor.location}</Typography>
                  <HighlightText variant="body2">{visitor.date}</HighlightText>
                  <HighlightText variant="body2">{visitor.timing}</HighlightText>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={scrollToBottom}
                    sx={{ marginTop: "10px", textTransform: "none" }} // Ensures the button text is not transformed to uppercase
                  >
                    Take Appointment
                  </Button>
                </CardContent>
              </CardWrapper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default DoctorsVisitors;
