import React from "react";
import AboutHospital from "../components/AboutHospital";
import ImageSlider from "../components/ImageSlider";
import WhoWeAre from "../components/WhoWeAre";
import MeetOurTeam from "../components/MeetOurTeam";
import DoctorsVisitors from "../components/DoctorsVisitors";
import BottomBanner from "../components/BottomBanner";
import DeveloperBanner from "../components/DeveloperBanner";

const HomePage: React.FC = () => {
  return (
    <div className="commonPad">
      <AboutHospital />
      <ImageSlider />
      <WhoWeAre />
      <MeetOurTeam />
      <DoctorsVisitors />
      <BottomBanner />
      <DeveloperBanner />
    </div>
  );
};

export default HomePage;
