import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar)({
  minHeight: "70px",
  justifyContent: "center",
  backgroundColor: "#2E7D32", // Green color for a healthcare theme
  position: "sticky",
  top: 0,
  zIndex: 1000, // Ensure it stays above other content
  padding: "10px 0px",
});

const MenuButton = styled(Button)({
  color: "#fff",
  textTransform: "none", // Ensures the button text is not uppercase
  fontSize: "46px", // Bigger font size
  marginRight: "0px", // Adjust spacing if needed
  position: "absolute", // Keep it positioned on the right side
  right: "10px", // Push it towards the right end of the toolbar
  top: "50%", // Vertically align it using percentage
  transform: "translateY(-63%)", // Shift it upwards by half of its height to center it perfectly
});

const TopHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string) => {
    console.log(option);
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
        {/* Title */}
        <Typography
          variant="h4"
          component="div"
          style={{ flexGrow: 1, textAlign: "center", color: "#fff", fontFamily: "AppFont" }}
          paddingLeft={10}
          paddingRight={10}
        >
          Ayush PolyClinic & Ayurveda Hospital
        </Typography>

        {/* Menu Button */}
        <MenuButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          ...
        </MenuButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handleMenuItemClick("Enquiries")}>Enquiries</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Appointment")}>Appointment</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Report Status")}>Report Status</MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopHeader;
