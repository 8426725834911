import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const BannerContainer = styled(Box)({
  padding: "20px",
  marginTop: "20px",
  backgroundColor: "#2E7D32",
  color: "#fff",
  textAlign: "center",
  fontFamily: "AppFont",
});

const BottomBanner: React.FC = () => {
  return (
    <BannerContainer id="bottomBanner">
      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>

      <Typography variant="h6" gutterBottom>
        Director - DR. Maganlal Patidar
      </Typography>
      <Typography variant="body2">
        BAMS, CCH, CGO MUMBAI
        <br />
        Monday – Sunday
        <br />
        Time :- 9AM – 7PM
        <br />
        Mobile - 9829059350
        <br />
        Address - Shreeji complex, Opp. Hamid Petrol Pump, <br />
        Galiyakot Road, Badgi, Dungarpur, Rajasthan - 314035
      </Typography>

      <Typography variant="h6" gutterBottom className="callForEm">
        Call for Emergency/Appoinment - +91 - 9829059350, 8619063899
      </Typography>
    </BannerContainer>
  );
};

export default BottomBanner;
